import React, { useState } from 'react';
import { Container, Form, Button, ProgressBar, Alert } from 'react-bootstrap';
import foundationalQuestions from '../../data/foundation_questions';
import industryQuestions from '../../data/industry_questions';

const industries = [
    "Financial Services",
    "Healthcare",
    "Government",
    "Energy and Utilities",
    "Manufacturing",
    "Retail",
    "Technology",
    "Transportation",
    "Education",
    "Media and Entertainment",
    "Defense and Aerospace",
    "Professional Services"
];

const employeeSizes = ["1-5", "6-10", "11-50", "51-200", "201+"];

const ContextQuestions = ({ context, onContextChange, validated }) => (
    <Form noValidate validated={validated}>
        <Form.Group className="mb-3" style={{ maxWidth: "500px" }}>
            <Form.Label>Industry</Form.Label>
            <Form.Select
                value={context.industry}
                onChange={(e) => onContextChange('industry', e.target.value)}
                required
            >
                <option value="">Select your industry</option>
                {industries.map((industry, index) => (
                    <option key={index} value={industry}>{industry}</option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Please select an industry.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" style={{ maxWidth: "500px" }}>
            <Form.Label>Number of Employees</Form.Label>
            <Form.Select
                value={context.employeeSize}
                onChange={(e) => onContextChange('employeeSize', e.target.value)}
                required
            >
                <option value="">Select number of employees</option>
                {employeeSizes.map((size, index) => (
                    <option key={index} value={size}>{size}</option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Please select the number of employees.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" style={{ maxWidth: "500px" }}>
            <Form.Label>Do you have a dedicated IT staff?</Form.Label>
            <Form.Select
                value={context.hasDedicatedIT}
                onChange={(e) => onContextChange('hasDedicatedIT', e.target.value)}
                required
            >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="outsourced">We outsource IT</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Please select an option.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" style={{ maxWidth: "500px" }}>
            <Form.Label>How would you rate your overall cybersecurity knowledge?</Form.Label>
            <Form.Select
                value={context.securityKnowledge}
                onChange={(e) => onContextChange('securityKnowledge', e.target.value)}
                required
            >
                <option value="">Select your knowledge level</option>
                <option value="novice">Novice</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
                <option value="expert">Expert</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Please select your knowledge level.
            </Form.Control.Feedback>
        </Form.Group>
    </Form>
);

const SurveyQuestion = ({ question, response, onResponseChange, validated }) => (
    <Form.Group className="mb-4">
        <Form.Label>{question.question}</Form.Label>
        {question.options.map((option, index) => (
            <Form.Check
                key={index}
                type="radio"
                id={`${question.id}-${index}`}
                label={option.label}
                name={question.id}
                value={option.value}
                onChange={(e) => onResponseChange(question.id, parseInt(e.target.value))}
                checked={response === option.value}
                required
                isInvalid={validated && response === undefined}
            />
        ))}
        <Form.Control.Feedback type="invalid">
            Please select an answer.
        </Form.Control.Feedback>
        <Form.Text className="text-muted">
            Relevant standards: {question.standards}
        </Form.Text>
    </Form.Group>
);

const SurveyPage = ({ questions, responses, onResponseChange, onNext, onPrev, onSubmit, isFirstPage, isLastPage, validated }) => (
    <Form noValidate validated={validated} onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
        {questions.map((q) => (
            <SurveyQuestion
                key={q.id}
                question={q}
                response={responses[q.id]}
                onResponseChange={onResponseChange}
                validated={validated}
            />
        ))}
        <div className="d-flex justify-content-between">
            {!isFirstPage && <Button variant="secondary" onClick={onPrev}>Previous</Button>}
            {!isLastPage && <Button variant="primary" onClick={onNext}>Next</Button>}
            {isLastPage && <Button type="submit" variant="primary">Submit</Button>}
        </div>
    </Form>
);

const ConfirmationScreen = ({ onRestart }) => (
    <div className="text-center">
        <h2>Thank you for completing the survey!</h2>
        <p>Your responses have been recorded.</p>
        <Button variant="primary" onClick={onRestart}>Start Over</Button>
    </div>
);

const SurveyManager = () => {
    const [context, setContext] = useState({
        industry: '',
        employeeSize: '',
        hasDedicatedIT: '',
        securityKnowledge: ''
    });
    const [responses, setResponses] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [validated, setValidated] = useState(false);
    const questionsPerPage = 4;

    const handleContextChange = (field, value) => {
        setContext(prevContext => ({
            ...prevContext,
            [field]: value
        }));
    };

    const handleResponseChange = (questionId, value) => {
        setResponses(prevResponses => ({
            ...prevResponses,
            [questionId]: value
        }));
    };

    const handleSubmit = () => {
        if (isLastPage && Object.keys(responses).length === allQuestions.length) {
            console.log({ context, responses });
            setIsComplete(true);
        } else {
            setValidated(true);
        }
    };

    const handleNextPage = () => {
        if (currentPage === 0) {
            if (Object.values(context).every(value => value !== '')) {
                setCurrentPage(prev => prev + 1);
                setValidated(false);
            } else {
                setValidated(true);
            }
        } else {
            const currentQuestions = getCurrentQuestions();
            if (currentQuestions.every(q => responses[q.id] !== undefined)) {
                setCurrentPage(prev => prev + 1);
                setValidated(false);
            } else {
                setValidated(true);
            }
        }
    };

    const handlePrevPage = () => {
        setCurrentPage(prev => prev - 1);
        setValidated(false);
    };

    const handleRestart = () => {
        setContext({
            industry: '',
            employeeSize: '',
            hasDedicatedIT: '',
            securityKnowledge: ''
        });
        setResponses({});
        setCurrentPage(0);
        setIsComplete(false);
        setValidated(false);
    };

    // Get industry-specific questions based on selected industry
    const industrySpecificQuestions = industryQuestions[context.industry] || [];

    // Combine foundation questions with industry-specific questions
    const allQuestions = [...foundationalQuestions, ...industrySpecificQuestions];

    const getCurrentQuestions = () => {
        if (currentPage === 1) {
          return industryQuestions.filter(q => q.industry === context.industry) || [];
        } else {
          const startIndex = (currentPage - 2) * questionsPerPage;
          const endIndex = startIndex + questionsPerPage;
          return foundationalQuestions.slice(startIndex, endIndex);
        }
      };

    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage > 1 && (currentPage - 2) * questionsPerPage >= foundationalQuestions.length;

    const totalQuestions = Object.keys(context).length + foundationalQuestions.length + industrySpecificQuestions.length;
    const answeredQuestions = Object.keys(responses).length + (currentPage > 0 ? Object.keys(context).length : 0);
    const progress = (answeredQuestions / totalQuestions) * 100;

    if (isComplete) {
        return <ConfirmationScreen onRestart={handleRestart} />;
    }

    return (
        <Container>
            <h1>Cybersecurity Survey</h1>
            <ProgressBar now={progress} label={`${Math.round(progress)}%`} className="mb-4" />
            {validated && <Alert variant="danger">Please answer all questions before proceeding.</Alert>}
            {isFirstPage ? (
                <>
                    <ContextQuestions context={context} onContextChange={handleContextChange} validated={validated} />
                    <Button variant="primary" onClick={handleNextPage}>Next</Button>
                </>
            ) : (
                <SurveyPage
                    questions={getCurrentQuestions()}
                    responses={responses}
                    onResponseChange={handleResponseChange}
                    onNext={handleNextPage}
                    onPrev={handlePrevPage}
                    onSubmit={handleSubmit}
                    isFirstPage={isFirstPage}
                    isLastPage={isLastPage}
                    validated={validated}
                />
            )}
        </Container>
    );
};

export default SurveyManager;