import React from 'react';

function Settings() {
    return (
        <div className="custom-container">
            <h1>Settings</h1>
            <div className="content-area">
                <p>lalalalala</p>
            </div>
        </div>
    );
}

export default Settings;