import React, { Fragment } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv';
import { faFile, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { faSignsPost } from '@fortawesome/free-solid-svg-icons/faSignsPost';
import { faChalkboardUser } from '@fortawesome/free-solid-svg-icons/faChalkboardUser';
import { supabase } from '../utils/supabase';
import { Link } from 'react-router-dom';
import Logo from '../cyght_logo.png';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons/faArrowRightFromBracket';

function NavBars({setCollapsed, collapsed}) {
    return (
        <Fragment>
            <Navbar bg="dark" variant="dark" expand="lg" className="sidebar d-lg-none">
                <Navbar.Brand href="/">
                    <img src={Logo} alt="Cyght logo" className="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setCollapsed(!collapsed)} />
                <div className={`${collapsed ? 'collapse' : ''} navbar-collapse`} id="basic-navbar-nav">
                    <Nav className="flex-column w-100">
                        <div className="user-menu-mobile" onClick={() => supabase.auth.signOut()}><FontAwesomeIcon icon={faArrowRightFromBracket} style={{ width: "20px", paddingRight: "15px" }} />Log out</div>
                        <Nav.Link as={Link} to="/" onClick={() => setCollapsed(!collapsed)}><FontAwesomeIcon icon={faTv} style={{ width: "20px", paddingRight: "15px" }} />Dashboard</Nav.Link>
                        <Nav.Link as={Link} to="/cyberscore" onClick={() => setCollapsed(!collapsed)}><FontAwesomeIcon icon={faChartPie} style={{ width: "20px", paddingRight: "15px" }} />Cyber Score</Nav.Link>
                        <Nav.Link as={Link} to="/policies" onClick={() => setCollapsed(!collapsed)}><FontAwesomeIcon icon={faFile} style={{ width: "20px", paddingRight: "15px" }} />Policies</Nav.Link>
                        <Nav.Link as={Link} to="/assets" onClick={() => setCollapsed(!collapsed)}><FontAwesomeIcon icon={faRectangleList} style={{ width: "20px", paddingRight: "15px" }} />Asset Manager</Nav.Link>
                        <Nav.Link as={Link} to="/datamapping" onClick={() => setCollapsed(!collapsed)}><FontAwesomeIcon icon={faSignsPost} style={{ width: "20px", paddingRight: "15px" }} />Data Mapping</Nav.Link>
                        <Nav.Link as={Link} to="/training"onClick={() => setCollapsed(!collapsed)}><FontAwesomeIcon icon={faChalkboardUser} style={{ width: "20px", paddingRight: "15px" }} />Training</Nav.Link>
                        <Nav.Link as={Link} to="/settings" onClick={() => setCollapsed(!collapsed)}><FontAwesomeIcon icon={faGear} style={{ width: "20px", paddingRight: "15px" }} />Settings</Nav.Link>
                    </Nav>
                </div>
            </Navbar>

            <div className="sidebar d-none d-lg-block">
                <div className="logo-container">
                    <img src={Logo} alt="Cyght logo" className="logo" />
                </div>
                <Nav className="flex-column" style={{ marginTop: "80px" }}>
                    <Nav.Link as={Link} to="/"><FontAwesomeIcon icon={faTv} style={{ width: "20px", paddingRight: "15px" }}/>Dashboard</Nav.Link>
                    <Nav.Link as={Link} to="/cyberscore"><FontAwesomeIcon icon={faChartPie} style={{ width: "20px", paddingRight: "15px" }} />Cyber Score</Nav.Link>
                    <Nav.Link as={Link} to="/policies"><FontAwesomeIcon icon={faFile} style={{ width: "20px", paddingRight: "15px" }} />Policies</Nav.Link>
                    <Nav.Link as={Link} to="/assets"><FontAwesomeIcon icon={faRectangleList} style={{ width: "20px", paddingRight: "15px" }} />Asset Manager</Nav.Link>
                    <Nav.Link as={Link} to="/datamapping"><FontAwesomeIcon icon={faSignsPost} style={{ width: "20px", paddingRight: "15px" }} />Data Mapping</Nav.Link>
                    <Nav.Link as={Link} to="/training"><FontAwesomeIcon icon={faChalkboardUser} style={{ width: "20px", paddingRight: "15px" }} />Training</Nav.Link>
                    <Nav.Link as={Link} to="/settings"><FontAwesomeIcon icon={faGear} style={{ width: "20px", paddingRight: "15px" }} />Settings</Nav.Link>
                </Nav>
            </div>
        </Fragment>
    )
}

export default NavBars;