const industryQuestions = [
    {
        "id": "FS1",
        "industry": "Financial Services",
        "category": "Data Protection",
        "question": "Do you use special software to protect financial data when it's being sent over the internet or stored on computers?",
        "options": [
            { "label": "Yes, for all data", "value": 3 },
            { "label": "For some data", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "PCI DSS, FFIEC"
    },
    {
        "id": "FS2",
        "industry": "Financial Services",
        "category": "Fraud Detection",
        "question": "Do you have a way to detect unusual financial transactions that might be fraudulent?",
        "options": [
            { "label": "Yes, automated system", "value": 3 },
            { "label": "Yes, manual checks", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "PCI DSS, FFIEC"
    },
    {
        "id": "FS3",
        "industry": "Financial Services",
        "category": "Data Encryption",
        "question": "Do you use encryption for sensitive financial data at rest and in transit?",
        "options": [
            { "label": "Yes, always", "value": 3 },
            { "label": "Sometimes", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "PCI DSS, GDPR"
    },
    {
        "id": "HC1",
        "industry": "Healthcare",
        "category": "Data Privacy",
        "question": "Do you have rules and technology in place to keep patient information private and secure?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No formal measures", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "HIPAA (US), HITECH (US)"
    },
    {
        "id": "HC2",
        "industry": "Healthcare",
        "category": "Device Security",
        "question": "If you use medical devices that connect to the internet, do you have special security measures for these?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A - no connected devices", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "HIPAA (US), FDA guidelines"
    },
    {
        "id": "HC3",
        "industry": "Healthcare",
        "category": "HIPAA Compliance",
        "question": "Do you conduct regular risk assessments as required by HIPAA?",
        "options": [
            { "label": "Yes, regularly", "value": 3 },
            { "label": "Sometimes", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "HIPAA"
    },
    {
        "id": "GV1",
        "industry": "Government",
        "category": "Compliance",
        "question": "Are you following the specific cybersecurity rules set by your government agency or department?",
        "options": [
            { "label": "Yes, fully compliant", "value": 3 },
            { "label": "Partially compliant", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "FISMA (US), UK Government Security Policy Framework"
    },
    {
        "id": "GV2",
        "industry": "Government",
        "category": "Secure Sharing",
        "question": "Do you have a system for securely sharing sensitive information with other government agencies or contractors?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No formal system", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "FISMA (US), UK Government Security Policy Framework"
    },
    {
        "id": "GV3",
        "industry": "Government",
        "category": "Data Classification",
        "question": "Do you have a system for classifying and handling sensitive government data?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Basic system", "value": 2 },
            { "label": "No system", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "FISMA, UK Government Security Policy Framework"
    },
    {
        "id": "EU1",
        "industry": "Energy and Utilities",
        "category": "Industrial Systems",
        "question": "Do you have special protection for the computer systems that control your industrial equipment or infrastructure?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NERC CIP (US), NIS Directive (EU)"
    },
    {
        "id": "EU2",
        "industry": "Energy and Utilities",
        "category": "Continuity Planning",
        "question": "Do you have a plan for keeping critical services running if there's a cyber attack?",
        "options": [
            { "label": "Yes, detailed plan", "value": 3 },
            { "label": "Yes, basic plan", "value": 2 },
            { "label": "No plan", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NERC CIP (US), NIS Directive (EU)"
    },
    {
        "id": "EU3",
        "industry": "Energy and Utilities",
        "category": "OT/IT Segregation",
        "question": "Are your operational technology (OT) networks segregated from IT networks?",
        "options": [
            { "label": "Yes, completely", "value": 3 },
            { "label": "Partially", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NERC CIP, NIS Directive"
    },
    {
        "id": "MF1",
        "industry": "Manufacturing",
        "category": "Supply Chain",
        "question": "Do you have secure ways of communicating with your suppliers and partners?",
        "options": [
            { "label": "Yes, for all communication", "value": 3 },
            { "label": "For some communication", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NIST CSF, ISO 27001"
    },
    {
        "id": "MF2",
        "industry": "Manufacturing",
        "category": "Smart Equipment",
        "question": "If you use smart or connected manufacturing equipment, do you have special security measures for these?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A - no smart equipment", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NIST CSF, IEC 62443"
    },
    {
        "id": "RT1",
        "industry": "Retail",
        "category": "Payment Security",
        "question": "Are all your payment systems (like cash registers or online shopping carts) protected against hacking?",
        "options": [
            { "label": "Yes, all systems", "value": 3 },
            { "label": "Some systems", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "PCI DSS"
    },
    {
        "id": "RT2",
        "industry": "Retail",
        "category": "Fraud Detection",
        "question": "Do you have a way to detect fake or malicious shopping activity on your website?",
        "options": [
            { "label": "Yes, automated system", "value": 3 },
            { "label": "Yes, manual monitoring", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A - no online shop", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "PCI DSS, OWASP"
    },
    {
        "id": "RT3",
        "industry": "Retail",
        "category": "POS Security",
        "question": "Are your point-of-sale systems isolated from other networks?",
        "options": [
            { "label": "Yes, completely", "value": 3 },
            { "label": "Partially", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "PCI DSS"
    },
    {
        "id": "TC1",
        "industry": "Technology",
        "category": "Secure Development",
        "question": "When developing software or apps, do you check for security issues throughout the whole process?",
        "options": [
            { "label": "Yes, at every stage", "value": 3 },
            { "label": "At some stages", "value": 2 },
            { "label": "Only at the end", "value": 1 },
            { "label": "No", "value": 0 },
            { "label": "N/A", "value": "N/A" }
        ],
        "maxScore": 3,
        "standards": "OWASP, NIST CSF"
    },
    {
        "id": "TC2",
        "industry": "Technology",
        "category": "Vulnerability Management",
        "question": "Do you have a process for finding and fixing security flaws in your products before they reach customers?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No formal process", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "OWASP, ISO 27034"
    },
    {
        "id": "TL1",
        "industry": "Transportation",
        "category": "Connected Systems",
        "question": "If you use connected vehicles or traffic systems, do you have special cybersecurity measures for these?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "Auto-ISAC (US), ENISA (EU)"
    },
    {
        "id": "TL2",
        "industry": "Transportation",
        "category": "Continuity Planning",
        "question": "Do you have a plan for keeping operations running if your digital systems are attacked?",
        "options": [
            { "label": "Yes, detailed plan", "value": 3 },
            { "label": "Yes, basic plan", "value": 2 },
            { "label": "No plan", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NIST CSF, ISO 22301"
    },
    {
        "id": "ED1",
        "industry": "Education",
        "category": "Student Data Protection",
        "question": "Do you have special protections for student data, including grades, personal information, and online activity?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No special protections", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "FERPA (US), GDPR (EU)"
    },
    {
        "id": "ED2",
        "industry": "Education",
        "category": "Online Learning Security",
        "question": "If you offer online learning, do you have security measures in place to protect these systems?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A - no online learning", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NIST CSF, ISO 27001"
    },
    {
        "id": "ME1",
        "industry": "Media and Entertainment",
        "category": "IP Protection",
        "question": "Do you have systems in place to protect your intellectual property (like unreleased content) from being stolen or leaked?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No formal systems", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "DMCA (US), Copyright Directive (EU)"
    },
    {
        "id": "ME2",
        "industry": "Media and Entertainment",
        "category": "User Data Protection",
        "question": "If you collect user data (like viewing habits), do you have strong protections for this information?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A - don't collect data", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "GDPR (EU), CCPA (US)"
    },
    {
        "id": "DA1",
        "industry": "Defense and Aerospace",
        "category": "Contract Compliance",
        "question": "Are you meeting the specific cybersecurity standards required for defense contracts?",
        "options": [
            { "label": "Yes, fully compliant", "value": 3 },
            { "label": "Partially compliant", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "N/A", "value": "N/A" },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "CMMC (US), UK Defence Cyber Protection Partnership"
    },
    {
        "id": "DA2",
        "industry": "Defense and Aerospace",
        "category": "Sensitive Data Protection",
        "question": "Do you have extra-strong protections for sensitive technical data or designs?",
        "options": [
            { "label": "Yes, comprehensive", "value": 3 },
            { "label": "Yes, basic", "value": 2 },
            { "label": "No special protections", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "ITAR (US), UK Official Secrets Act"
    },
    {
        "id": "PS1",
        "industry": "Professional Services",
        "category": "Client Communication",
        "question": "Do you have secure ways of communicating sensitive client information, like using encrypted email or secure file sharing?",
        "options": [
            { "label": "Yes, for all communication", "value": 3 },
            { "label": "For some communication", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "Various professional ethics codes, GDPR (EU)"
    },
    {
        "id": "PS2",
        "industry": "Professional Services",
        "category": "Document Management",
        "question": "Do you have a way to securely store and manage client documents and data?",
        "options": [
            { "label": "Yes, comprehensive system", "value": 3 },
            { "label": "Yes, basic system", "value": 2 },
            { "label": "No formal system", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "ISO 27001, GDPR (EU)"
    }
]

export default industryQuestions;