import React from 'react';

function NoMatch() {
    return (
        <div className="custom-container">
            <h1>Oops - we couldn't find that page!</h1>
            <div className="content-area">
                <p>lalalalala</p>
            </div>
        </div>
    );
}

export default NoMatch;