import Survey from "./survey/Survey";

function CyberScore() {
    return (
        <div className="custom-container">
            <h1>Cyber Score</h1>
            <div className="content-area">
                <Survey/>
            </div>
        </div>
    );
}

export default CyberScore;