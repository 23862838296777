import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function Notification() {
  let navigate = useNavigate();

    return (
        <>
          <Alert variant="info">
            <Alert.Heading>Welcome to Cyght!</Alert.Heading>
            <p>
              Start your Cyber Assessment to begin your journey to cybersecurity greatness.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => navigate("/cyberscore")} variant="outline-success">
                Start
              </Button>
            </div>
          </Alert>
        </>
      );
}

function Dashboard() {

    
    return (
        <div className="custom-container">
            <h1>Dashboard</h1>
            <div className="content-area">
                <Notification/>
            </div>
        </div>
    );
}

export default Dashboard;