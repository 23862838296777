import React, { useState, useEffect } from 'react';
import NavBars from './pages/NavBars';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { supabase } from './utils/supabase';
import Settings from './pages/Settings';
import Dashboard from './pages/Dashboard';
import NoMatch from './pages/NoMatch';
import CyberScore from './pages/CyberScore';
import Logo from './cyght_logo_b.png';
import { Col, Dropdown } from 'react-bootstrap';

export default function App() {

  const [collapsed, setCollapsed] = useState(true);
  const [session, setSession] = useState(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!session) {
    return (
      <div style={{ backgroundColor: "white", height: '100vh' }}>
        <Col xs={2} sm={2} md={4} />
        <Col xs={8} sm={8} md={8} style={{ paddingTop: '100px', margin: "auto", maxWidth: "300px" }}>
          <div style={{ textAlign: "center" }}>
            <img src={Logo} alt="Cyght Logo" height={40} style={{ margin: '30px 0' }} />
          </div>
          <Auth
            theme="dark"
            supabaseClient={supabase}
            appearance={{
              theme: ThemeSupa,
              style: {
                button: { background: '#2f61e2', color: 'white' },
                input: { background: 'white', color: 'black' },
              }
            }}
            providers={[]} showLinks={false} />
        </Col>
        <Col xs={2} sm={2} md={4} />
      </div>)
  } else {

    return (
      <Router>
        <div className="dashboard">
          <NavBars setCollapsed={setCollapsed} collapsed={collapsed} />
          <div className={`main-content ${collapsed ? 'pushed' : ''}`}>
            <div className="top-bar d-none d-lg-flex">
              <Dropdown>
                <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                  {session.user.email}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => supabase.auth.signOut()}>Log out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/cyberscore" element={<CyberScore />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </div>
        </div>
      </Router>
    );
  }
}