const foundationalQuestions = [
    {
        "id": "F1",
        "category": "Keeping Systems Current",
        "question": "How often do you update your computers and software?",
        "options": [
            { "label": "Always, as soon as updates are available", "value": 3 },
            { "label": "Most of the time", "value": 2 },
            { "label": "Rarely", "value": 1 },
            { "label": "Never", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "AU Essential 8, UK Cyber Essentials, NIST CSF"
    },
    {
        "id": "F2",
        "category": "Extra Login Security",
        "question": "Do you use a second step (like a code sent to your phone) when logging into important accounts?",
        "options": [
            { "label": "Yes, for all important accounts", "value": 3 },
            { "label": "Yes, for some accounts", "value": 2 },
            { "label": "No", "value": 0 },
            { "label": "I don't know what this means", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "AU Essential 8, UK Cyber Essentials, NIST CSF"
    },
    {
        "id": "F3",
        "category": "Data Safety",
        "question": "Do you regularly save copies of your important data and check if you can recover it?",
        "options": [
            { "label": "Yes, we save copies automatically and test recovery", "value": 3 },
            { "label": "Yes, we save copies but haven't tested recovery", "value": 2 },
            { "label": "No, we don't save regular copies", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "AU Essential 8, NZ NCSC, NIST CSF"
    },
    {
        "id": "F4",
        "category": "Staff Education",
        "question": "How often do you teach your employees about staying safe online?",
        "options": [
            { "label": "Regularly throughout the year", "value": 3 },
            { "label": "Occasionally", "value": 2 },
            { "label": "Only when they start working here", "value": 1 },
            { "label": "We don't do this", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "UK Cyber Essentials, NIST CSF, ISO 27001"
    },
    {
        "id": "F5",
        "category": "Virus Protection",
        "question": "Do you have up-to-date anti-virus software on your computers and devices?",
        "options": [
            { "label": "Yes, on all our devices", "value": 3 },
            { "label": "Yes, on most devices", "value": 2 },
            { "label": "On some devices", "value": 1 },
            { "label": "No", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "UK Cyber Essentials, NIST CSF"
    },
    {
        "id": "F6",
        "category": "User Permissions",
        "question": "Do you control who can access what on your business computers and systems?",
        "options": [
            { "label": "Yes, we have a thorough system for this", "value": 3 },
            { "label": "Yes, we have some basic rules", "value": 2 },
            { "label": "No, we don't really control this", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "AU Essential 8, NIST CSF, ISO 27001"
    },
    {
        "id": "F7",
        "category": "App Control",
        "question": "Do you have rules about what software can be installed on work computers?",
        "options": [
            { "label": "Yes, we have strict rules", "value": 3 },
            { "label": "We have some guidelines", "value": 2 },
            { "label": "No, anyone can install anything", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "AU Essential 8, NIST CSF"
    },
    {
        "id": "F8",
        "category": "Emergency Plan",
        "question": "Do you have a written plan for what to do if you have a cybersecurity problem?",
        "options": [
            { "label": "Yes, we have a detailed plan", "value": 3 },
            { "label": "Yes, we have a basic plan", "value": 2 },
            { "label": "No, we don't have a plan", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "NIST CSF, ISO 27001"
    },
    {
        "id": "F9",
        "category": "Password Rules",
        "question": "Do you have rules about creating strong passwords and using password managers?",
        "options": [
            { "label": "Yes, we require strong passwords and use a password manager", "value": 3 },
            { "label": "We require strong passwords but don't use a manager", "value": 2 },
            { "label": "We don't have specific password rules", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "UK Cyber Essentials, NIST CSF"
    },
    {
        "id": "F10",
        "category": "Network Protection",
        "question": "Do you use firewalls (digital security guards) to protect your business network?",
        "options": [
            { "label": "Yes, we use firewalls and configure them securely", "value": 3 },
            { "label": "We have firewalls but aren't sure about their setup", "value": 2 },
            { "label": "No, we don't use firewalls", "value": 0 },
            { "label": "I don't know what a firewall is", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "UK Cyber Essentials, NIST CSF"
    },
    {
        "id": "F11",
        "category": "Microsoft Office Safety",
        "question": "Have you set up Microsoft Office (like Word or Excel) to be more secure?",
        "options": [
            { "label": "Yes, we've set it to be very secure", "value": 3 },
            { "label": "We've made some security changes", "value": 2 },
            { "label": "No, we use the default settings", "value": 0 },
            { "label": "I'm not sure", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "AU Essential 8"
    },
    {
        "id": "F12",
        "category": "Legal Awareness",
        "question": "Are you aware of the cybersecurity laws and regulations that apply to your business?",
        "options": [
            { "label": "Yes, I'm very familiar with them", "value": 3 },
            { "label": "I know about some of them", "value": 2 },
            { "label": "I've heard of them but don't know details", "value": 1 },
            { "label": "I'm not aware of any", "value": 0 }
        ],
        "maxScore": 3,
        "standards": "GDPR, CCPA, HIPAA, etc."
    }
]

export default foundationalQuestions;